<!-- 
 * @description: 加入我们
 * @fileName: index.vue
 * @date: 2022-08-22 14:08:48
!-->
<template>
    <div class="joinUs">
       <div class="joinUs-content">
            <div class="joinUs-left">
                <div class="top-map">
                    <div class="concat-img">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/er.png">
                        <p>微信扫一扫了解更多详细信息</p>
                    </div>
                    <div class="concat-text">
                        <p>
                            <svg-icon icon-class="contacts-book-line"></svg-icon>
                            <span>联系人：陈老师</span>
                        </p>
                        <p>
                            <svg-icon icon-class="phone-line"></svg-icon>
                            <span>联系电话：15810818941</span>
                        </p>
                        <p>
                            <svg-icon icon-class="mail-line"></svg-icon>
                            <span>联系邮箱：chenhy@wedon.com</span>
                        </p>
                        <p>
                            <svg-icon icon-class="map-pin-line"></svg-icon>
                            <span>公司地址：北京市西城区北三环中路23号燕莎大厦5层</span>
                        </p>
                    </div>
                </div>
                <div class="bottom-map">
                    <div id="container">
                        <el-amap vid="amap" :plugin="plugin" class="amap-demo" :center="center" zoom=15.5>
                            <el-amap-marker
                                :position="center"
                                :label="{offset: [-20, -34] }"
                            />
                        </el-amap>
                    </div>
                </div>
            </div>
            <div class="joinUs-right">
                <el-form :model="formData" label-position="top" :rules="rules" ref="submitForm" label-width="100px" class="demo-formData">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="formData.name" placeholder="请输入您的真实姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="formData.phone" maxlength=11 placeholder="请输入您的联系电话"></el-input>
                    </el-form-item>
                    <el-form-item label="联系邮箱" prop="email">
                        <el-input v-model="formData.email" placeholder="请输入您的联系邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="所属公司" prop="company">
                        <el-input v-model="formData.company" placeholder="请输入您的所属公司"></el-input>
                    </el-form-item>
                    <el-form-item label="留言" prop="remark">
                        <el-input type="textarea" v-model="formData.remark" placeholder="请输入您想说的话" maxlength=1200></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"  @click="submitForm('submitForm')" class="submitBtn">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
       </div>
    </div>
</template>
<script>
const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
const isEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/

export const phoneValidator = (rule, value, callback) => {
    if ( isPhone.test(value) ) {
        callback()
    } else {
        callback(new Error('请填写正确的手机号'))
    }
}
export const emailValidator = (rule, value, callback) => {
    if ( isEmail.test(value) ) {
        callback()
    } else {
        callback(new Error('请输入正确的电子邮箱'))
    }
}
  export default {
    name:'JoinUs',
    data() {
      return {
        submitDisable:false,
        formData: {
          name: '',
          phone:'',
          company:'',
          email:'',
          remark: '',
          type:'国际互联网教育研究院'
        },
        center: [116.387108, 39.96902],
        rules: {
          name: [
            { required: true, message: '请您完成信息填写再提交', trigger: 'blur', }
          ],
          phone: [
            { required: true, message: '请您完成信息填写再提交', trigger: 'blur',validator:phoneValidator }
          ],
          remark: [
            { required: true, message: '请您完成信息填写再提交', trigger: 'blur' }
          ]
        },
        plugin: [{
            enableHighAccuracy: true,//是否使用高精度定位，默认:true
            timeout: 10000,          //超过10秒后停止定位，默认：无穷大
            maximumAge: 0,           //定位结果缓存0毫秒，默认：0
            convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
            showButton: true,        //显示定位按钮，默认：true
            buttonPosition: 'RB',    //定位按钮停靠位置，默认：'LB'，左下角
            showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
            panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
            zoomToAccuracy:true,//定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
            extensions:'all',
            pName: 'Geolocation',
            events: {
            }
        }]
      };
    },
    mounted(){
    },
    methods: {
      submitForm(formName) {
        if(this.submitDisable){
            return false
        }
        const that = this;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitDisable = true;
            const data = {
                ...this.formData
            }
            this.$api.joinUs.save(data).then((res) => {
                if(res.data){
                    that.$message({
                        message: '留言提交成功！',
                        type: 'success'
                    });
                    this.resetForm('submitForm');
                }
            }).finally(()=>{
                this.submitDisable = false;
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>
<style lang="less" scoped>
.joinUs{
    background:#F6F8FC;
    padding-top:16px;
    .joinUs-content{
        padding-top:84px;
        display: flex;
        width: 1200px;
        margin:0 auto;
        padding-bottom:76px;
        justify-content: center;
        background:#fff;
    }
}
.joinUs-right{
    margin-left: 130px;
    width:400px;
    .submitBtn{
        height:50px;
        line-height: 50px;
        width:400px;
        margin-top:30px;
        background:#0256FF;
        padding:0 24px;
        border:none;
        outline:none;
        appearance: none;
        /deep/span{
            font-size: 18px;
        }
    }
    .el-form-item{
        margin-bottom:23px;
    }
    ::placeholder { /* Firefox, Chrome, Opera */ 
        color: #D0D8E7;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */ 
        color: #D0D8E7; 
    }
    ::-ms-input-placeholder { /* Microsoft Edge */ 
        color: #D0D8E7; 
    } 
    /deep/.el-form-item__label{
        margin-bottom:8px;
        line-height: 26px;
        color:#333;
        padding-bottom:0;
        font-size: 18px;
        color:#333;
    }
    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after{
            content: '*';
            color: #F56C6C;
            margin-left: 4px;
    }
    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
            content: '';
            color: #F56C6C;
            margin-right: 4px;
    }
    /deep/.el-input{
        height:32px;
    }
    /deep/.el-form-item__content{
        line-height: 32px;
    }
    /deep/.el-input__inner{
        height: 32px;
        border:1px solid #D0D8E7;
        padding:5px 8px;
        color:#333;
        line-height: 32px;
    }
    /deep/.el-textarea__inner{
        padding:5px 8px;
        height: 120px;
        box-sizing: border-box;
    }
}
.joinUs-left{
    width:566px;
    .bottom-map{
        width:566px;
        height:300px;
        box-sizing: border-box;
        /deep/.el-vue-amap-container .el-vue-amap{
            height:300px;
        }
    }
    .top-map{
        margin-bottom:54px;
        display: flex;
    }
    .concat-img{
        padding-top:16px;
        width:200px;
        box-sizing: border-box;
        text-align: center;
        padding-left:25px;
        padding-right:25px;
        height:260px;
        border: 1px solid rgba(51,51,51,0.15);
        img{
            width:150px;
            height:150px;
        }
        p{
            margin-top: 16px;
            font-size: 20px;
            line-height: 28px;
            color:rgba(51,51,51,.85);
        }
    }
    .concat-text{
        line-height: 26px;
        margin-left: 50px;
        span{
            margin-left: 8px;
        }
        .icon{
            position: absolute;
            top:4px;
            left:0;
            font-size: 24px;
        }
        p{
            display: flex;
            padding-left: 32px;
            position: relative;
            font-size:18px;
            margin-bottom:40px;
            &:nth-last-child(1){
                margin-bottom:0;
            }
        }
    }
}
</style>
